var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "area-manage-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "800px",
            title: "区域管理",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "custom-class": "area-manage-dialog",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "operation-line" },
            [
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.addNewGroup } },
                [_vm._v("新增区域")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "table-header" }, [
            _c("span", { staticClass: "index-column" }, [_vm._v("序号")]),
            _c("span", { staticClass: "group-column" }, [_vm._v("区域名称")]),
            _c("span", { staticClass: "operation-column" }, [_vm._v("操作")]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "table-detail",
            },
            [
              _vm.tableDatas.length
                ? _c(
                    "div",
                    _vm._l(_vm.tableDatas, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.key,
                          class: [
                            "table-line",
                            index % 2 === 1 && "table-line-background",
                          ],
                        },
                        [
                          _c("span", { staticClass: "index-column" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(index + 1) +
                                "\n          "
                            ),
                          ]),
                          _c(
                            "span",
                            { class: ["group-column"] },
                            [
                              item.edit
                                ? _c("el-input", {
                                    ref: `areaNameInput_${index + 1}`,
                                    refInFor: true,
                                    attrs: {
                                      maxlength: "10",
                                      "show-word-limit": true,
                                    },
                                    model: {
                                      value: item.areaName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "areaName", $$v)
                                      },
                                      expression: "item.areaName",
                                    },
                                  })
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.areaName) +
                                        "\n            "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                          _c("span", { class: ["operation-column"] }, [
                            item.edit
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editStateChange(
                                              item,
                                              "ok"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确认")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: index !== 0,
                                            expression: "index !== 0",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.upHandler(index)
                                          },
                                        },
                                      },
                                      [_vm._v("置顶")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editStateChange(
                                              item,
                                              "edit"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "delete-button",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteHandler(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "no-data" }, [_vm._v("暂无数据")]),
            ]
          ),
          _c(
            "div",
            { staticClass: "footer-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: { click: _vm.confirmHandler },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }