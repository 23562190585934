<template>
  <div
    class="app-container lego-config-list"
    v-loading="loading"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <div class="header-line">
      <i
        v-if="showSider"
        class="el-icon-arrow-left arrow-icon"
        @click="setTransformX('left')"
      ></i>
      <div class="tabs-part">
        <div
          class="tabs-container"
          :style="{
            transform: `translateX(${translateX}px)`,
          }"
        >
          <span
            :class="['single-tab', item.active && 'single-tab-active']"
            v-for="item in groupList"
            :key="item.areaId"
            @click="setTabActive(item)"
            :title="item.areaName"
          >
            {{ item.areaName }}
          </span>
        </div>
      </div>
      <i
        v-if="showSider"
        class="el-icon-arrow-right arrow-icon"
        @click="setTransformX('right')"
      ></i>
      <div class="dropdown-part">
        <el-dropdown @command="handleCommand">
          <el-button
            type="primary"
            style="background: #2a61ff; border-radius: 8px"
          >
            操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="newConfig">新增小程序</el-dropdown-item>
            <el-dropdown-item command="groupManagement"
              >区域管理</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="card-list" v-on:scroll="containerScroll">
      <div
        class="group-container"
        :ref="`group_${item.areaId}`"
        :id="`group_${item.areaId}`"
        v-for="item in groupList"
        :key="item.areaId"
      >
        <div class="group-label">
          {{ item.areaName }}
        </div>
        <div class="no-card" v-if="!item.miniprogramList.length">暂无数据</div>
        <div class="card-container" v-else>
          <el-row :gutter="30" style="width: 100%">
            <el-col
              :span="6"
              v-for="(item, index) in item.miniprogramList"
              :key="item.cardId"
            >
              <SingleConfigCard
                :dataset="item"
                :isFirst="index === 0"
                :currentAreaId="item.areaId"
                :areaList="areaListMap"
                @close="initDatas"
                @toDetail="jumpToDetail"
                ref="singleCardRef"
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <AreaManageDialog :dataset="areaDialog" @close="areaManageDialogClose" />
    <MiniAddDialog
      :dataset="addDialog"
      :areaOptions="areaListMap"
      :projectList="projectList"
      :merchantList="merchantList"
      @close="
        () => {
          addDialog.show = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SingleConfigCard from "./components/singleConfigCard.vue";
import AreaManageDialog from "./components/areaManageDialog.vue";
import MiniAddDialog from "./components/miniAddDialog.vue";
import { getMiniprogramList } from "@/api/ruge/lego/config";
import { getProjectList } from "@/api/business/base/tenant/map";
import { getMerchantList } from "@/api/ruge/lego/config";

export default {
  name: "lego-config-list",
  components: {
    SingleConfigCard,
    AreaManageDialog,
    MiniAddDialog,
  },
  data() {
    return {
      loading: false,
      showSider: false,
      translateX: 0,
      tabsDiff: 0,
      scrollLock: false,
      groupList: [],
      areaListMap: [],
      areaDialog: {
        show: false,
      },
      addDialog: {
        show: false,
      },
      projectList: [],
      merchantList: [],
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    this.initDatas();
    this.$eventBus.$off("mini-popover-close");
    this.$eventBus.$on("mini-popover-close", (cardId) => {
      this.clearPopover(cardId);
    });
  },
  methods: {
    initDatas() {
      this.loading = true;
      getMiniprogramList()
        .then((res) => {
          this.groupList = res.map((item, index) => {
            item.active = index === 0;
            return item;
          });
          this.areaListMap = res.map((item) => {
            return {
              areaName: item.areaName,
              areaId: item.areaId,
            };
          });
        })
        .finally(() => {
          const { miniprogramId } = this.$route.query;
          if (miniprogramId) {
            this.setScrollTopById(miniprogramId);
          }
          this.loading = false;
        });
      getProjectList().then((res) => {
        this.projectList = res;
      });
      getMerchantList({
        current: 1,
      }).then((res) => {
        this.merchantList = res.data.rows;
      });
    },
    setScrollTopById(miniprogramId) {
      this.$nextTick(() => {
        try {
          const targetDom = document.getElementById(`card_${miniprogramId}`);
          targetDom.scrollIntoView({});
          // 移除查询参数 miniprogramId
          const newQuery = { ...this.$route.query };
          delete newQuery.miniprogramId;
          this.$router.replace({ query: newQuery });
          // 边框高亮
          targetDom.style.border = "1px solid #1A4CEC";
          setTimeout(() => {
            targetDom.style.border = `1px solid #eef2f6`;
          }, 2000);
        } catch (error) {
          console.log("定位卡片失败：", error);
        }
      });
    },
    jumpToDetail(miniprogramId) {
      this.$router.push({
        path: "/lego/config/edit",
        query: {
          layout: "hide",
          miniprogramId,
        },
      });
    },
    setTransformX(direction) {
      if (direction === "left") {
        if (this.translateX >= 0) return;
        this.translateX += 200;
      } else {
        if (this.translateX <= -this.tabsDiff) return;
        this.translateX -= 200;
      }
    },
    setTabActive({ areaId }) {
      this.scrollLock = true;
      let activeIndex = 0;
      this.groupList.forEach((item, index) => {
        if (item.areaId === areaId) {
          this.setScrollTopByGroupId(item.areaId);
          activeIndex = index;
        }
        item.active = item.areaId === areaId;
      });
      setTimeout(() => {
        this.scrollLock = false;
        this.scrollTabIntoView(activeIndex);
      }, 100);
    },
    setScrollTopByGroupId(areaId) {
      try {
        const targetDom = document.getElementById(`group_${areaId}`);
        targetDom.scrollIntoView({});
      } catch (error) {
        console.log("定位卡片失败：", error);
      }
    },
    scrollTabIntoView(activeIndex) {
      const oneTabWidth = 120;
      const containerWidth =
        document.getElementsByClassName("tabs-part")[0].offsetWidth;
      const middleNumber = (containerWidth / oneTabWidth / 2).toFixed(0);
      if (activeIndex > middleNumber) {
        if (this.translateX <= -this.tabsDiff) return;
        this.translateX = 0 - oneTabWidth * (activeIndex - middleNumber);
      } else {
        this.translateX = 0;
      }
    },
    areaManageDialogClose(freshFlag) {
      this.areaDialog.show = false;
      freshFlag && this.initDatas();
    },
    handleCommand(command) {
      switch (command) {
        case "groupManagement":
          this.areaDialog.show = true;
          break;
        case "newConfig":
          this.addDialog.show = true;
          break;
      }
    },
    clearPopover(cardId) {
      if (this.$refs.singleCardRef && this.$refs.singleCardRef.length) {
        this.$refs.singleCardRef.forEach((ins) => {
          ins.popoverClose(cardId);
        });
      }
    },
    setActiveByIndex(setIndex) {
      this.groupList.forEach((item, index) => {
        item.active = setIndex === index;
      });
      this.scrollTabIntoView(setIndex);
    },
    containerScroll() {
      this.clearPopover();
      if (this.scrollLock) return;
      const containerDom = document.getElementsByClassName("card-list")[0];
      const offsetTop = containerDom.scrollTop;
      if (
        containerDom.scrollTop + containerDom.clientHeight ==
        containerDom.scrollHeight
      ) {
        this.setActiveByIndex(this.groupList.length - 1);
        return;
      }
      // 计算子元素高度区间
      // [248,664,336]
      const heightList = [];
      this.groupList.forEach((item, index) => {
        const currentItem = document.getElementById(`group_${item.areaId}`);
        const currentHeight = currentItem.offsetHeight;
        const preHeight = heightList[index - 1] || 0;
        heightList.push(currentHeight + preHeight);
      });
      for (let i = 0; i < heightList.length; i++) {
        if (offsetTop <= heightList[i]) {
          this.setActiveByIndex(i);
          break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lego-config-list {
  // 解决用scrollIntoView父容器会滑动的问题
  position: fixed;
  .header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f4f6;
    .arrow-icon {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      top: -5px;
    }
    .tabs-part {
      width: calc(100% - 180px);
      overflow: hidden;
      position: relative;
      height: 37px;
      .tabs-container {
        transition: transform 0.2s;
        width: max-content;
        .single-tab {
          display: inline-block;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          color: #5d687c;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          height: 37px;
          line-height: 25px;
          cursor: pointer;
          margin-right: 20px;
        }
        .single-tab-active {
          color: #252d3d;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 2px;
            background: #2a61ff;
            border-radius: 7px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 10px);
          }
        }
      }
    }
    .dropdown-part {
      text-align: right;
      position: relative;
      top: -5px;
    }
  }
  .card-list {
    height: calc(100% - 60px);
    overflow: auto;
    .group-container {
      .group-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252d3d;
        margin-bottom: 16px;
        position: relative;
        padding: 5px 18px;
        background: linear-gradient(90deg, #eff3f7 0%, #ffffff 100%);
        border-radius: 2px;
        &::before {
          content: "";
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #2a61ff;
          position: absolute;
          left: 9px;
          top: 6px;
        }
      }
      .no-card {
        font-weight: 400;
        font-size: 14px;
        color: #b5bece;
        margin: 10px 0 30px;
      }
    }
  }
}
</style>