var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-preview-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.closeHandler,
            width: "860px",
            title: "预览",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mini-preview-container" },
            [
              _vm.dataset.show
                ? _c("SingleMiniPreview", { attrs: { dataset: _vm.dataset } })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }