<template>
  <div class="miniprogram-card-component">
    <div
      :id="`card_${dataset.miniprogramId}`"
      class="cards-container"
      @click="$emit('toDetail', dataset.miniprogramId, 'edit')"
    >
      <div
        class="card-image"
        :style="{
          backgroundImage:
            'url(' +
            require(`@/assets/images/lego/home_background_${currentTheme}.png`) +
            ')',
        }"
      >
        <div class="mini-name-line">
          <i class="el-icon-location-outline"></i>
          <span class="name-span">
            {{ dataset.miniprogramTitle }}
          </span>
          <i class="el-icon-arrow-down"></i>
        </div>
        <img
          class="title-image"
          src="@/assets/images/lego/home_head_img.png"
          alt=""
        />
        <img
          class="default-image"
          src="@/assets/images/lego/mini_default_background.png"
          alt=""
        />
      </div>
      <div class="card-name-line">
        <span class="name-left">
          <span class="name-span">
            {{ dataset.miniprogramTitle }}
          </span>
          <span :class="`card-state-${dataset.miniprogramStatus}`">
            {{ dataset.miniprogramStatus === "DRAFT" ? "草稿" : "已发布" }}
          </span>
          <span :class="`card-enable-${dataset.enableStatus}`">
            {{ dataset.enableStatus === "ENABLE" ? "启用" : "禁用" }}
          </span>
        </span>
        <span class="more-span" @click.stop="popoverClick(dataset)">
          <el-popover
            ref="popover"
            popper-class="more-span-popover"
            placement="bottom-start"
            width="400"
            trigger="click"
          >
            <div class="cascader-container">
              <div v-for="item in cascaderOptions" :key="item.key">
                <div
                  v-if="!(item.key === 'setShare' && dataset.canShared === 0)"
                  :class="[
                    'cascader-first-level',
                    item.key === 'delete' && 'first-level-delete',
                    item.disable && 'first-level-disable',
                  ]"
                  @click="firstLevelClick(item)"
                  @mouseenter="(event) => firstLevelEnter(event, item)"
                  @mouseleave="(event) => firstLevelLeave(event, item)"
                >
                  <span>
                    {{ item.label }}
                  </span>
                  <span v-if="item.children">
                    <i class="el-icon-arrow-right"></i>
                  </span>
                  <div class="cascader-second-container">
                    <div
                      class="cascader-second-level"
                      v-for="element in item.children"
                      :key="element.key"
                      :title="element.label"
                      @click="moveToGroupHandler(element)"
                    >
                      {{ element.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-button class="more-button" slot="reference"
              ><img
                class="more-icon"
                src="@/assets/images/bms/more_icon.png"
                alt=""
            /></el-button>
          </el-popover>
        </span>
      </div>
      <div class="bottom-line">
        <span class="name-and-date">
          <span>
            {{ dataset.updateUserName || "System" }}
          </span>
          <span>
            {{ dataset.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </span>
        </span>
      </div>
    </div>

    <!-- 重命名弹框 -->
    <RenameDialog
      v-if="rename.show"
      :dataset="rename"
      @close="renameDialogClose"
    />

    <!-- 预览弹框 -->
    <PreviewDialog
      :dataset="previewDialogDatas"
      @close="
        () => {
          previewDialogDatas.show = false;
        }
      "
    />
  </div>
</template>

<script>
import {
  moveToTop,
  cardRename,
  deleteCard,
} from "@/api/ruge/bms/indicatorManagement";
import PreviewDialog from "./previewDialog.vue";
import RenameDialog from "./renameDialog.vue";
import {
  moveMiniprogramArea,
  sortTopMiniprogram,
  deleteMiniprogram,
  updateMiniprogramEnableStatus,
} from "@/api/ruge/lego/config";

export default {
  name: "miniprogram-card-component",
  components: {
    PreviewDialog,
    RenameDialog,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    isFirst: {
      type: Boolean,
    },
    areaList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentAreaId: {
      type: String,
      default() {
        return "-1";
      },
    },
  },
  watch: {
    currentAreaId: {
      handler(val) {
        this.setOptionGroup();
      },
      immediate: true,
    },
    areaList: {
      handler(val) {
        this.setOptionGroup();
      },
      immediate: true,
    },
  },
  computed: {
    currentTheme() {
      try {
        const parsedData = JSON.parse(this.dataset.pageConfig);
        console.log("parsedData", parsedData);
        return parsedData.theme;
      } catch (error) {
        return "blue";
      }
    },
  },
  data() {
    return {
      cascaderOptions: [
        {
          label: "预览",
          key: "preview",
        },
        {
          label: this.dataset.enableStatus === "ENABLE" ? "禁用" : "启用",
          key: "miniprogramEnableStatus",
        },
        {
          label: "重命名",
          key: "rename",
        },
        {
          label: "移到最前",
          key: "toTop",
          disable: this.isFirst,
        },
        {
          label: "发布记录",
          key: "realeaseRecord",
          disable: this.dataset.isPublished === 0,
        },
        {
          label: "移动至分组",
          key: "moveToGroup",
          children: [],
        },
        {
          label: "删除",
          key: "delete",
        },
      ],
      rename: {
        show: false,
        miniprogramTitle: null,
        miniprogramId: null,
      },
      previewDialogDatas: {
        show: false,
        datas: this.dataset,
      },
      releaseRecord: {
        show: false,
      },
    };
  },
  methods: {
    renameDialogClose(freshFlag) {
      this.rename.show = false;
      freshFlag === true && this.$emit("close");
    },
    popoverClick({ miniprogramId }) {
      this.$eventBus.$emit("mini-popover-close", miniprogramId);
    },
    firstLevelEnter(event, datas) {
      $(".cascader-second-container").hide();
      if (!datas.children) return;
      const { pageX } = event;
      const { innerWidth } = window;
      const SafeWidth = 160;
      // left/right
      let classSet = "cascader-";
      classSet += pageX + SafeWidth > innerWidth ? "left" : "right";
      const containerDom = event.target;
      $(containerDom)
        .find(".cascader-second-container")
        .addClass(classSet)
        .show();
    },
    firstLevelLeave(event, datas) {
      if (!datas.children) return;
      const containerDom = $(event.target);
      containerDom
        .find(".cascader-second-container")
        .removeClass("cascader-left cascader-right");
    },
    async enableStatusChange() {
      const { enableStatus, miniprogramId } = this.dataset;
      const tipsMap = {
        ENABLE: "确认禁用该小程序吗？",
        DISABLE: "确认启用该小程序吗？",
      };
      await this.$confirm(tipsMap[enableStatus], this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      const params = {
        miniprogramId,
        enableStatus: enableStatus === "ENABLE" ? "DISABLE" : "ENABLE",
      };
      updateMiniprogramEnableStatus(params).then(() => {
        this.$message.success("删除成功！");
        this.$emit("close");
      });
    },
    firstLevelClick(item) {
      if (item.disable) return;
      switch (item.key) {
        case "toTop":
          this.toTopHandler();
          break;
        case "miniprogramEnableStatus":
          this.enableStatusChange();
          break;
        case "rename":
          this.rename.miniprogramTitle = this.dataset.miniprogramTitle;
          this.rename.miniprogramId = this.dataset.miniprogramId;
          this.rename.show = true;
          break;
        case "delete":
          this.cardDeleteHandler();
          break;
        case "preview":
          this.previewDialogDatas.show = true;
          break;
        case "realeaseRecord":
          this.$router.push({
            path: "/lego/releaseRecord",
            query: {
              miniprogramId: this.dataset.miniprogramId,
              areaId: this.dataset.areaId,
              miniprogramTitle: this.dataset.miniprogramTitle,
              layout: "hide",
            },
          });
          break;
      }
      this.popoverClose();
    },
    cardDeleteHandler() {
      this.$confirm(`确认删除该小程序吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          const { miniprogramId } = this.dataset;
          deleteMiniprogram(miniprogramId).then(() => {
            this.$message.success("删除成功！");
            this.$emit("close");
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    renameHandler(cardStatus) {
      // PUBLISH | DRAFT
      this.$refs.renameForm.validate((valid) => {
        if (valid) {
          const { miniprogramId } = this.dataset;
          const miniprogramTitle = this.rename.form.miniprogramTitle;
          cardRename({ cardStatus, miniprogramId, miniprogramTitle })
            .then(() => {
              this.$message.success("保存成功！");
              this.$emit("close");
            })
            .finally(() => {
              this.rename.form.miniprogramTitle = null;
              this.rename.show = false;
            });
        }
      });
    },
    toTopHandler() {
      const { miniprogramId } = this.dataset;
      sortTopMiniprogram({
        miniprogramId,
        areaId: this.currentAreaId,
      }).then(() => {
        this.$message.success(`移动成功！`);
        this.$emit("close");
      });
    },
    setOptionGroup() {
      if (!this.currentAreaId || !this.areaList.length) {
        return;
      }
      const tempList = this.areaList.filter(
        (item) => item.areaId !== this.currentAreaId
      );
      this.cascaderOptions.forEach((item) => {
        if (item.key === "moveToGroup") {
          item.children = tempList.map((ele) => {
            return {
              label: ele.areaName,
              key: ele.areaId,
            };
          });
        }
        if (item.key === "toTop") {
          item.disable = this.isFirst;
        }
        if (item.key === "realeaseRecord") {
          item.disable = this.dataset.isPublished === 0;
        }
        if (item.key === "miniprogramEnableStatus") {
          item.label = this.dataset.enableStatus === "ENABLE" ? "禁用" : "启用";
        }
      });
      this.$forceUpdate();
    },
    moveToGroupHandler({ key, label }) {
      this.popoverClose();
      const { miniprogramTitle, miniprogramId } = this.dataset;
      moveMiniprogramArea({
        miniprogramId,
        areaId: key,
      }).then(() => {
        this.$message.success(
          `【${miniprogramTitle}】已移动至【${label}】分组`
        );
        this.$emit("close");
      });
    },
    popoverClose(miniprogramId) {
      if (this.dataset.miniprogramId !== miniprogramId) {
        this.$refs.popover.doClose();
      }
    },
    setImgByDefault(item) {
      this.clearAllSelect(item.key);
      this.imageUrl = item.icon;
      this.coverPicture.form.coverPicture = item.key;
    },
    clearAllSelect(activeKey) {
      this.coverPicture.defaulList.forEach((ele) => {
        ele.selected = ele.key === activeKey;
      });
    },
    setDefaultImage() {
      /**
       * 三种情况：
       *  13431 - 上传图片的ID
       *  1     - 选择的默认图片
       *  null  - 没有上传过
       */
      const coverMap = {
        bar: 1,
        pie: 2,
        line: 3,
      };
      // if (this.dataset.coverPicture) {
      //   // 有设置cover
      //   this.imageUrl = this.coverImageRender(this.dataset.coverPicture);
      this.clearAllSelect();
      // } else {
      const current =
        this.dataset.coverPicture || coverMap[this.dataset.cardType];
      this.imageUrl = this.coverImageRender(current);
      this.clearAllSelect(current);
      // }
      this.coverPicture.form.coverPicture =
        coverMap[this.dataset.coverPicture] ||
        this.dataset.coverPicture ||
        coverMap[this.dataset.cardType];
    },
  },
};
</script>

<style lang="less" scoped>
.miniprogram-card-component {
  .cards-container {
    cursor: pointer;
    height: 252px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(142, 142, 142, 0.1);
    border-radius: 10px;
    border: 1px solid #eef2f6;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .card-image {
    height: 174px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: -2px;
    border-radius: 8px 8px 0 0;
    background-size: 100% 100%;
    .mini-name-line {
      position: absolute;
      top: 39px;
      left: 8px;
      font-weight: 500;
      font-size: 14px;
      color: #30313b;
      .name-span {
        display: inline-block;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 2px;
      }
      i {
        position: relative;
        top: -1px;
      }
    }
    .title-image {
      height: 64px;
    }
    .default-image {
      height: 103px;
      margin: 0 7px;
    }
    // &:hover {
    //   .card-cover {
    //     transform: scale(1.2);
    //   }
    // }
    // .card-cover {
    //   width: 100%;
    //   height: 100%;
    //   transition: all 0.8s;
    // }
  }
  .card-name-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin: 16px 0 10px;
    .name-left {
      display: inline-flex;
      align-items: center;
      max-width: 80%;
      .name-span {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 16px;
        color: #252d3d;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 6px;
      }
      .card-state-DRAFT {
        font-size: 10px;
        color: #a7b4c7;
        background: rgba(155, 159, 168, 0.15);
        border-radius: 4px;
        padding: 3px 4px;
      }
      .card-state-PUBLISH {
        color: #2a61ff;
        background: rgba(42, 97, 255, 0.15);
        border-radius: 4px;
        padding: 3px 4px;
        font-size: 10px;
      }
      .card-enable-ENABLE {
        color: #0dded1;
        background: rgba(13, 222, 209, 0.15);
        padding: 3px 4px;
        border-radius: 4px;
        font-size: 10px;
        margin-left: 4px;
      }
      .card-enable-DISABLE {
        color: #f13636;
        background: rgba(237, 152, 152, 0.15);
        padding: 3px 4px;
        border-radius: 4px;
        font-size: 10px;
        margin-left: 4px;
      }
    }
    .more-span {
      display: inline-block;
      width: 28px;
      text-align: right;
      border-radius: 4px;
      .more-button {
        padding: 0;
        width: 28px;
        border: none;
      }
      .more-icon {
        cursor: pointer;
      }
      &:hover {
        background: #f5f5f6;
      }
    }
  }
  .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin-bottom: 14px;
    .name-and-date {
      font-weight: 400;
      font-size: 12px;
      color: #a7b4c7;
      span + span {
        margin-left: 8px;
      }
    }
    .size-span {
      display: inline-block;
      width: 28px;
      height: 16px;
      line-height: 16px;
      background: #2a61ff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      margin-left: 6px;
    }
  }
}
</style>
<style lang="less">
.upload-container {
  display: flex;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
.avatar-uploader {
  height: 177px;
  width: 300px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 177px;
  width: 300px;
  line-height: 177px;
  text-align: center;
}
.avatar {
  height: 177px;
  width: 300px;
  display: block;
}
.select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .single-card {
    width: 200px;
    height: 118px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #eef2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;
    .example-img {
      width: 200px;
      height: 118px;
      border-radius: 10px;
    }
    .corner-img {
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      border-color: #2a61ff;
    }
  }
  .single-card-selected {
    border: 1px solid #2a61ff;
  }
}
.more-span-popover {
  width: 117px !important;
  padding: 0;
  .cascader-container {
    .cascader-first-level {
      font-weight: 500;
      font-size: 14px;
      color: #152c5b;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        color: #2a61ff;
        background: #f5f5f6;
        .cascader-second-container {
          display: block;
        }
      }
      .cascader-second-container {
        display: none;
        position: absolute;
        background: #ffffff;
        width: 148px;
        max-height: 270px;
        overflow: auto;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .cascader-second-level {
          font-weight: 500;
          font-size: 14px;
          color: #152c5b;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            background: #f5f5f6;
          }
        }
      }
      .cascader-left {
        bottom: -100%;
        left: -100%;
      }
      .cascader-right {
        bottom: -100%;
        right: -100%;
      }
    }
    .first-level-disable {
      color: #adadad;
      cursor: not-allowed;
      &:hover {
        color: #adadad;
      }
    }
    .first-level-delete {
      color: #ff0000;
      &:hover {
        color: #ff0000;
        background: #f5f5f6;
      }
    }
  }
}
</style>