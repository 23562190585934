<template>
  <div class="area-manage-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="800px"
      title="区域管理"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      custom-class="area-manage-dialog"
      append-to-body
    >
      <div class="operation-line">
        <r-button plain @click="addNewGroup">新增区域</r-button>
      </div>
      <div class="table-header">
        <span class="index-column">序号</span>
        <span class="group-column">区域名称</span>
        <span class="operation-column">操作</span>
      </div>
      <div class="table-detail" v-loading="loading">
        <div v-if="tableDatas.length">
          <div
            :class="['table-line', index % 2 === 1 && 'table-line-background']"
            v-for="(item, index) in tableDatas"
            :key="item.key"
          >
            <span class="index-column">
              {{ index + 1 }}
            </span>
            <span :class="['group-column']">
              <el-input
                :ref="`areaNameInput_${index + 1}`"
                v-if="item.edit"
                v-model="item.areaName"
                maxlength="10"
                :show-word-limit="true"
              ></el-input>
              <span v-else>
                {{ item.areaName }}
              </span>
            </span>
            <span :class="['operation-column']">
              <span v-if="item.edit">
                <el-button type="text" @click="editStateChange(item, 'ok')"
                  >确认</el-button
                >
              </span>
              <span v-else>
                <el-button
                  v-show="index !== 0"
                  type="text"
                  @click="upHandler(index)"
                  >置顶</el-button
                >
                <el-button type="text" @click="editStateChange(item, 'edit')"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  class="delete-button"
                  @click="deleteHandler(item, index)"
                  >删除</el-button
                >
              </span>
            </span>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>
      <div class="footer-line">
        <r-button type="cancel" @click="$emit('close')">取消</r-button>
        <r-button plain @click="confirmHandler" :loading="submitLoading"
          >确认</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAreaList, saveArea, deleteArea } from "@/api/ruge/lego/config";
import { cloneDeep } from "lodash";

export default {
  name: "area-manage-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  watch: {
    dataset: {
      handler(data) {
        if (data.show) {
          this.initDatas();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      tableDatas: [],
      loading: false,
      submitLoading: false,
    };
  },
  methods: {
    async initDatas() {
      this.loading = true;
      this.tableDatas = (await getAreaList()).map((item, index) => {
        item.index = index;
        item.edit = false;
        return item;
      });
      this.loading = false;
    },
    dialogCloseHandler() {
      this.$emit("close", true);
    },
    confirmHandler() {
      const emptyIndex = this.checkEmpty();
      if (emptyIndex !== undefined) {
        this.setEmptyInputFocus(emptyIndex);
        return;
      }
      this.submitLoading = true;
      saveArea(this.buildParams())
        .then((res) => {
          this.$message.success("保存分组成功！");
          this.$emit("close", true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    checkEmpty() {
      let index;
      for (let item of this.tableDatas) {
        if (!item.areaName) {
          index = item.index;
          break;
        }
      }
      return index;
    },
    buildParams() {
      const currentList = cloneDeep(this.tableDatas);
      return {
        miniprogramAreaList: currentList.map((item, index) => {
          return {
            areaId: item.areaId,
            orderNo: index + 1,
            areaName: item.areaName,
          };
        }),
      };
    },
    addNewGroup() {
      this.tableDatas.push({
        index: this.tableDatas.length + 1,
        areaName: "",
        edit: true,
      });
      this.scrollToBottom();
    },
    scrollToBottom() {
      try {
        this.$nextTick(() => {
          const scrollDom = document.getElementsByClassName("table-detail")[0];
          const scrollHeight = scrollDom.scrollHeight;
          scrollDom.scrollTo({ top: scrollHeight, behavior: "smooth" });
        });
      } catch (error) {}
    },
    upHandler(index) {
      const current = this.tableDatas.splice(index, 1)[0];
      this.tableDatas.splice(0, 0, current);
    },
    editStateChange(currentItem, type) {
      const emptyLine = this.tableDatas.filter((item) => !item.areaName);
      if (emptyLine.length === 0) {
        this.tableDatas.forEach((item) => {
          item.edit = false;
        });
        currentItem.edit = type === "edit" ? true : false;
      } else {
        const emptyIndex = emptyLine[0].index;
        this.setEmptyInputFocus(emptyIndex);
      }
    },
    setEmptyInputFocus(index) {
      try {
        this.$message.warning("请输入区域名称并确认！");
        const inputRef = this.$refs[`areaNameInput_${index}`][0];
        inputRef.focus();
      } catch (error) {}
    },
    async deleteHandler(item, index) {
      await this.$confirm(`确认要删除区域吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      if (item.areaId) {
        const res = await deleteArea(item.areaId);
        console.log("res", res);
      }
      this.tableDatas.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.area-manage-dialog {
  .operation-line {
    text-align: right;
  }
  .table-header {
    height: 46px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5d687c;
    font-weight: bold;
    background: #f0f4fa;
    margin-top: 20px;
    span {
      display: inline-block;
    }
  }
  .table-detail {
    min-height: 120px;
    max-height: 480px;
    overflow: auto;
    .no-data {
      text-align: center;
      color: #ccc;
      padding-top: 50px;
    }
  }
  .table-line {
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #252d3d;
    span {
      display: inline-block;
    }
  }
  .table-line-background {
    background: #fafbfe;
  }
  .disable-line {
    pointer-events: none;
    color: #b5bece !important;
    ::v-deep .el-button {
      color: #b5bece !important;
      cursor: not-allowed;
    }
  }
  .delete-button {
    color: #ff0000;
  }
  .index-column {
    width: 100px;
    text-align: center;
  }
  .group-column {
    flex: 1;
    text-align: center;
  }
  .operation-column {
    width: 150px;
    text-align: center;
  }
  .center {
    text-align: center;
  }
  .footer-line {
    text-align: right;
    margin: 15px 0;
  }
}
</style>